@import "~@/assets/scss/abstracts.scss";

.swiper-controls {
	display: initial;
	position: initial;
	transform: none;
	@include mobile {
		display: flex;
		width: 100%;
		position: absolute;
		bottom: -50px;
		left: 50%;
		transform: translateX(-50%);
		margin: 0 auto;
		align-items: center;
		justify-content: center;
		gap: 8px;
	}
	&-center {
		display: flex;
		position: absolute;
		bottom: -50px;
		left: 50%;
		margin: 0 auto;
		align-items: center;
		justify-content: center;
		gap: 8px;
		transform: translateX(-50%);
		@include mobile {
			position: initial;
			margin: 0;
			transform: none;
		}
	}
	.swiper-pagination-container {
		height: 36px;
		padding: 5px 14px;
		border-radius: 36px;
		border: 1px solid #e0e0e0;
		background-color: #fff;
		color: #757575;
		font-size: 16px;
		:deep(.swiper-pagination-current) {
			font-weight: 700;
		}
	}
	.swiper-button {
		&-playstop,
		&-prev,
		&-next {
			display: flex;
			width: 36px;
			height: 36px;
			border-radius: 50%;
			border: 1px solid #e0e0e0;
			background-color: rgba(255, 255, 255, 0.8);
			align-items: center;
			justify-content: center;
			.icon {
				width: 20px;
				height: 20px;
				svg {
					width: 100%;
					height: 100%;
				}
			}
		}
		&-playstop {
			background-color: #fff;
		}
		&-prev,
		&-next {
			box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15);
			@include mobile {
				position: initial;
				margin: 0;
				box-shadow: none;
			}
		}
		&-prev {
			left: -20px;
			.icon {
				margin-left: -4px;
				transform: rotate(180deg);
			}
		}
		&-next {
			right: -20px;
			.icon {
				margin-right: -4px;
			}
		}
	}
}
